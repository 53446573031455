
<template>
  <div>
    <BaseHeaderBar
      :showCustomerService="false"
      title=""
      :showBackButton="true"
    />
    <div class="login">
      <img class="login_bg" src="@/assets/img/login-bg.png" alt="" />
      <div class="title">
        {{ title }}
      </div>
      <form class="login_form" @submit.prevent="onSubmit">
        <div class="input-block">
          <input
            v-model.trim="phone"
            type="tel"
            autofocus
            placeholder="Masukkan nomor handphone dengan awalan 08"
            maxlength="13"
          />
        </div>
        <div v-if="showPasswordInput" class="input-block">
          <input
            v-model.trim="password"
            maxlength="8"
            type="tel"
            class="password"
            :placeholder="tipText"
          />
        </div>
        <div class="input-block verify-code-block">
          <input
            v-model.trim="verifyCode"
            type="tel"
            maxlength="4"
            placeholder="Masukkan 4 digit angka kode verifikasi"
          />
          <div class="verify-code-btn" v-if="isGetCode" @click="getVerifyCode">
            Dapatkan kode verifikasi
          </div>
          <div class="verify-code-btn count-down" v-else>{{ countDown }}s</div>
        </div>
        <button
          type="submit"
          :class="['confirm-btn', showPasswordInput ? 'near' : 'far']"
        >
          {{ buttonText }}
        </button>
      </form>

      <van-dialog
        v-model="visible"
        :showConfirmButton="false"
        close-on-click-overlay
        class="verify-img-dialog"
      >
        <img class="dialog_img" src="@/assets/img/dialog_img.png" alt="" />
        <h3 class="verify-title">Diperlukan verifikasi</h3>
        <div class="verify-body">
          <div class="verify-img">
            <img ref="verifyImg" @click="refreshVerifyCode" />
            <span @click="refreshVerifyCode">看不清？点击刷新</span>
          </div>

          <div class="verify-input-box">
            <input
              v-model="imgVerifyCode"
              placeholder="请输入图中字符"
              type="tel"
              maxlength="4"
            />
          </div>
          <div class="verify_btn">
            <button
              type="button"
              class="verify-confirm-btn"
              @click="onCloseDialog()"
            >
              取消
            </button>
            <button type="button" class="verify-confirm-btn" @click="onVerify">
              Yakin
            </button>
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Dialog, Icon } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword, validateVerifyCode } from '@/utils/validator'
import dialogVisible from '@/mixins/dialogVisible'
import { baseURL } from '@/config'

Vue.use(Icon)

Vue.use(Toast)
const maxTime = 150
let timer
const clearTimer = () => {
  timer && clearTimeout(timer) && (timer = null)
}
const titleMap = {
  forgetPassword: 'Lupa Password',
  messageLogin: 'Login dengan SMS',
  register: 'Daftar'
}
const buttonText = {
  forgetPassword: 'Yakin',
  messageLogin: 'Login',
  register: 'Daftar'
}
const apiMap = {
  forgetPassword: 'usdrerForgetPassword',
  messageLogin: 'usdrerLogin',
  register: 'usdrerRegistration'
}

const messageTypeMap = {
  forgetPassword: 3,
  messageLogin: 2,
  register: 1
}

export default {
  name: 'LoginForm',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mixins: [dialogVisible],
  data () {
    return {
      phone: '',
      password: '',
      verifyCode: '',
      imgVerifyCode: '',
      isGetCode: true,
      countDown: maxTime,
      verifyImg: '',
      verifyImgCodes: [
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        }
      ],
      errorInfo: ''
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  computed: {
    title () {
      return titleMap[this.type]
    },
    buttonText () {
      return buttonText[this.type]
    },
    showPasswordInput () {
      return this.type === 'forgetPassword' || this.type === 'register'
    },
    tipText () {
      if (this.type === 'register') {
        return 'Atur 8 digit password'
      } else {
        return 'Masukkan 8 digit angka password'
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.getImgVerifyCode()
          this.$refs.input0[0].focus()
        })
      }
    }
  },
  methods: {
    validatePhoneNumber () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      return true
    },
    validate () {
      if (!this.validatePhoneNumber()) return false
      if (this.showPasswordInput) {
        if (!this.password) {
          Toast(this.tipText)
          return false
        }
        if (!validatePassword(this.password)) {
          Toast('Password salah')
          return false
        }
      }
      if (!this.verifyCode) {
        Toast('Masukkan 4 digit angka kode verifikasi')
        return false
      }
      if (!validateVerifyCode(this.verifyCode)) {
        Toast('Kode verifikasi salah')
        return false
      }
      return true
    },
    startCountDown () {
      if (this.countDown <= 0) {
        this.reset()
      } else {
        timer = setTimeout(() => {
          this.countDown--
          this.startCountDown()
        }, 1000)
      }
    },
    getVerifyCode () {
      if (!this.validatePhoneNumber()) return
      const postData = {
        usdrerPhone: this.phone,
        tydrpe: messageTypeMap[this.type],
        smdrsType: 1
      }
      this.imgVerifyCode && (postData.imdrgCode = this.imgVerifyCode)
      request.post('usdrersendMessages', postData)
        .then(() => {
          Toast('Kode verifikasi terkirim. Silakan cek')
          this.countDown = maxTime
          this.isGetCode = false
          this.startCountDown()
        })
        .catch(err => {
          if (err.code === 504) {
            this.visible = true
          }
          this.reset()
        })
    },
    getImgVerifyCode () {
      this.$refs.verifyImg.src = `${baseURL}usdrergetGraphic?usdrerPhone=${this.phone}&t=${+Date.now()}`
    },
    reset () {
      clearTimer()
      this.isGetCode = true
      this.countDown = maxTime
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        usdrerPhone: this.phone,
        smdrs_Verificationcode: this.verifyCode,
        smdrsType: 1
      }
      if (this.type === 'forgetPassword') {
        postData.usdrerPassword = this.password
      } else if (this.type === 'messageLogin') {
        postData.lodrginType = 2
      } else if (this.type === 'register') {
        const vienintele = sessionStorage.getItem('vienintele')
        const apitype = sessionStorage.getItem('apitype')
        if (vienintele) {
          postData.vienintele = vienintele
        }
        if (apitype) {
          postData.apitype = apitype
        }
        postData.usdrerPassword = this.password
        postData.prdromotionChannel = 'AppStore'
      }
      request.post(apiMap[this.type], postData)
        .then(res => {
          if (this.type === 'forgetPassword') {
            const { query } = this.$route
            this.$router.push({
              path: '/login',
              query
            })
          } else if (this.type === 'register' || this.type === 'messageLogin') {
            this.$store.commit('recordInfo', {
              type: 'userInfo',
              value: res
            })
            localStorage.setItem('userInfo', JSON.stringify(res))
            if (this.type === 'register') {
              this.addressBook()
            }
            this.$router.push('/home1')
          }
        })
        .finally(() => {
          this.flag = false
        })
    },
    onVerify () {
      const postData = {
        usdrerPhone: this.phone,
        grdraphicCode: this.imgVerifyCode
      }
      request.post('usdrercheckGraphic', postData)
        .then(res => {
          Toast('Verifikasi Berhasil')
          this.errorInfo = ''
          this.visible = false
        }).catch(() => {
          this.getImgVerifyCode()
          this.errorInfo = '验证码错误'
        })
    },
    refreshVerifyCode () {
      this.getImgVerifyCode()
    },
    onInput (index) {
      if (this.verifyImgCodes[index].value && index < 3) {
        console.log('onInput', index)
        this.$refs[`input${index + 1}`][0].focus()
      }
    },
    onDelete (index) {
      if (index > 0) {
        this.$refs[`input${index - 1}`][0].focus()
        console.log('onDelete', index)
      }
    }
  },
  deactivated () {
    this.reset()
  }
}
</script>

<style scoped lang="less">
.login {
  padding: 0 37px;
  .login_bg {
    width: 635px;
    height: 331px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .title {
    font-size: 54px;
    font-weight: 600;
    color: #fff;
    margin-top: 10px;
    text-align: center;
  }
  .login_form {
    display: flex;
    flex-direction: column;
    // justify-content: end;
    padding-top: 200px;
    height: 502px;
    padding-bottom: 140px;
    background: url("../assets/img/wangjimima_bg.png") no-repeat;
    background-size: 100% 100%;
  }
}

.input-block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  &:nth-child(n + 2) {
    margin-top: 24px;
  }
  img {
    width: 48.03px;
    height: 48.03px;
  }

  input {
    display: block;
    width: 100%;
    line-height: 100px;
    padding-left: 30px;
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 30px;
    color: #333;
    background: none;
    height: 100px;
    background: #cec7e7;
    border-radius: 50px 50px 50px 50px;
    color: #9432cd;
    &::placeholder {
      font-size: 22px;
      font-weight: 400;
      color: #b549fe;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.password {
  -webkit-text-security: disc;
}

.verify-code-block {
  display: flex;
  margin-bottom: 60px;
}

.verify-code-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  right: 70px;
  width: 182px;
  padding: 10px;
  text-align: center;
  font-size: 24px;
  color: #9432cd;
  background: #ebe9f5;
  border-radius: 36.5px;
}

.count-down {
  padding-top: 0;
  line-height: 88px;
}

.confirm-btn {
  .submit-btn;
}

.verify-title {
  font-size: 54px;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
}

.verify-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    margin-top: 30px;
    width: 250px;
    height: 100px;
    margin-bottom: 10px;
    background: url("../assets/img/verify_code_bg.png") no-repeat;
    background-size: 100% 100%;
  }
  span {
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    line-height: 60px;
  }
}

.refresh-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 100%;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }
}

.verify-input-box {
  display: flex;
  justify-content: space-between;
  margin: 0 50px;
  margin-top: 24px;

  div {
    flex: 1;

    &:nth-child(n + 2) {
      margin-left: 64px;
    }
  }

  input {
    width: 100%;
    line-height: 88px;
    padding: 0;
    padding-left: 20px;
    border-radius: 50px;
    box-sizing: border-box;
    font-size: 30px;
    color: #b549fe;
    background: #433185;
    border: none;
    &::placeholder {
      font-size: 30px;
      font-weight: 400;
      color: #bcb6b6;
    }
  }

  .error-info {
    font-size: 24px;
    color: #ff710e;
    position: absolute;
    right: 80px;
    line-height: 88px;
  }
}
.verify_btn {
  display: flex;
  gap: 30px;
  padding: 0 30px;
}
.verify-confirm-btn {
  .submit-btn;
  width: 546px;
  margin-top: 32px;
  margin-bottom: 54px;
}
.verify-confirm-btn:nth-child(1) {
  background: url("../assets/img/button_bg2.png") no-repeat;
  background-size: 100% 100%;
}

.close-icon {
  position: absolute;
  top: 30px;
  right: 25px;
}
.verify-img-dialog {
  padding-top: 150px;
  .dialog_img {
    width: 473px;
    height: 351px;
    position: absolute;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
